<template>
  <div class="w-96">
    <form @submit.prevent="onSubmit">

      <PAlert 
        v-if="success" 
        class="text-center" 
        type="success"
        v-html="$tk('ForgotPassword.ConfirmText')"
      ></PAlert>
      
      <div v-else>
        
        <PAlert 
          type="error" 
          v-if="error" 
          class="text-center"
          v-html="$tk('ForgotPassword.ErrorSendingLink')"
        ></PAlert>
        
        <PAlert 
          type="info" 
          v-else
          v-html="$tk('ForgotPassword.InfoText')"
        ></PAlert>
        
        <PInput 
          class="mt-4" 
          :label="$tk('Common.General.Username')" 
          v-model="username" 
        />

        <div class="mt-8 flex items-center space-x-2">

          <PButton 
            color="primary" 
            type="submit" 
            :disabled="!canSubmit" 
            :loading="isSubmitting"            
          >
            <span v-html="$tk('ForgotPassword.SendRequest')"></span>
          </PButton>

          <router-link 
            to="login" 
            class="text-sm hover:underline" 
            v-html="$tk('Common.Actions.Cancel')"
          ></router-link>

        </div>
      </div>
    </form>
  </div>
</template>

<script>
import http from "@/http"
export default {
  name: "ForgotPassword",
  data () {
    return {
      username: "",
      isSubmitting: false,
      error: null,
      success: false,
      url: ""
    }
  },
  computed: {
    canSubmit () {
      return this.username !== ""
    }
  }, 
  methods: {
    async onSubmit () {
      this.error = null

      let options = {
        userLoginName: this.username,
        url: this.url
      }

      try {
        await http.post("UserPasswordRequest", options)
        this.success = true
      } catch {
        this.error = {
          message: this.$tk("ForgotPassword.ErrorSendingLink")
        }
      }
      
    }
  },
  created () {
    this.url = location.protocol + "//" +  location.host + "/reset-password"
    //this.$refs.input.focus() TODO: make it possibe to ref PInput
  }
}
</script>